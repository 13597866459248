import axios from '../libs/axios';
// 年卡列表
const yearList = (data) => {
  return axios.get('/api/trd/year/list', data);
};

// 修改
const yearEdit = (data) => {
  return axios.post('/api/trd/year/save', data);
};

// 信息
const yearInfo = (id) => {
  return axios.post(`/api/trd/year/info/${id}`);
};

// 提交年卡充值规则
const yearRechargeSubmit = (data) => {
  return axios.post('/api/trd/recharge/year/save', data);
};
// 年卡充值列表
const yearRechargeList = (data) => {
  return axios.get('/api/trd/recharge/year/list', data);
};

export {
  yearList,
  yearInfo,
  yearEdit,
  yearRechargeSubmit,
  yearRechargeList
};
